<template>
    <div id="pushButtonEventTypesList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="pushButtonEventTypes"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="alert_pushButtonAlertEventTypeListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newPushButtonEventType' }" id="eventTypes_createEventTypeButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("alert_pushButtonAlertAddEventType") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="pushButtonEventTypes" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M3.5,3 L5,3 L5,19.5 C5,20.3284271 4.32842712,21 3.5,21 L3.5,21 C2.67157288,21 2,20.3284271 2,19.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z" fill="#000000"/>
                                                            <path d="M6.99987583,2.99995344 L19.754647,2.99999303 C20.3069317,2.99999474 20.7546456,3.44771138 20.7546439,3.99999613 C20.7546431,4.24703684 20.6631995,4.48533385 20.497938,4.66895776 L17.5,8 L20.4979317,11.3310353 C20.8673908,11.7415453 20.8341123,12.3738351 20.4236023,12.7432941 C20.2399776,12.9085564 20.0016794,13 19.7546376,13 L6.99987583,13 L6.99987583,2.99995344 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("alert_pushButtonAlertEventTypeList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" @keyup="onSearch" v-model="searchedValue" id="eventTypes_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="PUSHBUTTON_EVENT_TYPE" mainTextKey="alert_pushButtonEventTypeNotFound" subTextKey="alert_pushButtonEventTypeNotFoundSubLabel" imgName="empty_notifications.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="eventTypes_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteEventTypesTable: null,
            isEmptyList: false,
            isMounted: false,
            searchedValue: ""
        };
    },
    created: function() {
        console.log("Component(sitePushButtonEventTypes)::created() - called");
        if (this.createAnotherPushButtonEventType) {
            this.$router.push({ name: "newPushButtonEventType" });
        } else {
            this.getSiteById(this.siteId);
            // Load user sites from backend and store the result in sites store.
            this.getSitePushButtonEventTypes(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(sitePushButtonEventTypes)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(sitePushButtonEventTypes)::destroyed() - called");
        this.resetPushButtonEventTypeState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        sitePushButtonEventTypes: function() {
            console.log("Component(sitePushButtonEventTypes)::watch(sitePushButtonEventTypes) called");
            this.initKtDataTable();
        },

        user: function(user) {
            if (user) {
                console.log("Component(sitePushButtonEventTypes)::watch(user) called with : ", user);
                this.searchedValue = "";
                this.initKtDataTable();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["sitePushButtonEventTypes", "createAnotherPushButtonEventType", "user", "currentSite"]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSitePushButtonEventTypes", "getSiteById", "showDeleteModal", "resetPushButtonEventTypeState"]),

        onSearch() {
            if (this.siteEventTypesTable) {
                this.siteEventTypesTable.search(this.searchedValue, "translateName");
            }
        },

        onEditEventType: function(eventTypeId) {
            this.$router.push({ name: "editPushButtonEventType", params: { eventTypeId: eventTypeId, siteId: this.siteId } });
        },

        registerEventsOnSiteEventTypesTable() {
            var self = this;
            $("#eventTypes_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteEventType")
                    .off()
                    .on("click", function() {
                        let eventTypeId = $(this).attr("data-eventtypeid");
                        let eventTypeName = $(this).attr("data-eventtypename");
                        console.log("Component(sitePushButtonEventTypes)::registerEventsOnBuildingsTable(deleteEventType) clicked. EventTypeId = " + eventTypeId);
                        self.showDeleteModal({ textContent: i18n.t("alert_deletePushButtonEventTypeConfirmText", { eventTypeName: eventTypeName }), actionName: "DELETE_PUSHBUTTON_EVENT_TYPE", params: { siteId: self.siteId, eventTypeId: eventTypeId } });
                    });
                $(".editEventType")
                    .off()
                    .on("click", function() {
                        let eventTypeId = $(this).attr("data-eventtypeid");
                        console.log("Component(sitePushButtonEventTypes)::registerEventsOnBuildingsTable(editEventType) clicked. EventTypeId = " + eventTypeId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditEventType(eventTypeId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        buildDataTableDataSource() {
            // Prepare data before init datatable
            let dataSource = [];
            if (this.sitePushButtonEventTypes) {
                dataSource = this.sitePushButtonEventTypes.map((eventType) => {
                    let finalEventType = {...eventType};
                    // Translate category name
                    if (eventType.isDefault && eventType.name) {
                        finalEventType.translateName = i18n.t("alert_pushEventType_" + eventType.name);
                    } else {
                        finalEventType.translateName = eventType.name;
                    }
                    return finalEventType;
                });

            }
            return dataSource;
        },

        initKtDataTable() {
            var self = this;
            const dataSource = this.buildDataTableDataSource();
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(dataSource),
                    pageSize: 10,
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "translateName",
                        title: i18n.t("common_name"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.isDefault) {
                                return '<span class="kt-user-card-v2__details">' + row.translateName + '</span>';
                            } else {
                                return '\
                                    <div class="kt-user-card-v2">\
                                        <div class="kt-user-card-v2__details openResourceLink">\
                                            <a class="editEventType kt-link kt-user-card-v2__name" data-eventtypeid="' + row.id + '" href="javascript:;">' + row.translateName + "</a>\
                                        </div>\
                                    </div>\
                                ";
                            }
                        }
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        textAlign: "center",
                        sortable: "desc",
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if (row.isDefault) {
                                // No authorized change
                                return "-";
                            }
                            return (
                                `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_edit") +
                                    `" type="button" data-eventtypeid="` + row.id + `" class="btn btn-outline-brand btn-sm btn-icon editEventType">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_delete") +
                                    `" type="button" data-eventtypeid="` + row.id + `" data-eventtypename="` + row.name + `" class="btn btn-outline-danger btn-sm btn-icon deleteEventType">
                                        <i class="la la-trash"></i>
                                    </button>
                                `
                            );
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteEventTypesTable) {
                $("#eventTypes_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteEventTypesTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteEventTypesTable = $("#eventTypes_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteEventTypesTable();
            } else {
                // There is no data => Show empty list icon+text
                // No table process: Comment lines below because table destroy previously
                this.isEmptyList = true;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
